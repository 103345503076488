<template>
  <div>

    <SalesStateEditorModal 
      ref="sales-state-modal"
      :item="selected_item"
      @updated="updated_agent_email"
      @created="created_agent_email"
    />
    
    <div>
      
      <b-row>
        
        <b-col lg="3" sm="12">
          
          <b-form-group
            :label="$t('SALES.ASSIGNED_AGENT')">

            <b-form-select
              v-model="filters.agent_id"
              :options="agent_options"
            ></b-form-select>

          </b-form-group>
        </b-col>

        <b-col lg="3" sm="12">
          
          <b-form-group
            :label="$t('SALES.EMAIL.DIRECTION')">

            <Multiselect
              class="multiselect-blue"
              v-model="filters.directions"
              ref="email_direction_multiselect"
              mode="tags"
              :placeholder="$t('SALES.EMAIL.DIRECTION')"
              :close-on-select="false"
              :filter-results="false"
              :min-chars="0"
              :searchable="false"
              :options="direction_options_mult"
            />

          </b-form-group>
        </b-col>

        
        <b-col lg="3" sm="12">
          <b-form-group
            id="input-group-created_from"
            :label="$t('PAGES.RENEWS.CREATED_FROM')"
            label-for="input-created_from"
          >
            <memlist-date-picker v-model="filters.created_from"></memlist-date-picker>
          </b-form-group>
          
        </b-col>
        <b-col lg="3" sm="12">
          <b-form-group
            id="input-group-created_to"
            :label="$t('PAGES.RENEWS.CREATED_TO')"
            label-for="input-created_to"
          >
            <memlist-date-picker v-model="filters.created_to"></memlist-date-picker>
          </b-form-group>
        </b-col>
        
      </b-row>

      <b-row>
        <b-col>
          <b-form-input
            id="filter-input"
            v-model="filters.text"
            type="search"
            style="max-width:621px"
            :placeholder="$t('COMMON.SEARCH')"
          ></b-form-input>
        </b-col>
        
      </b-row>
    </div>
    

    <div class="info-bar mt-8">
      <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ items.length }}
      <!-- Remove the pagination / per_page controls -->
    </div>

    <!-- Our "custom table" layout -->
    <div class="custom-table-container table-striped">

      <!-- Table header row (could be plain divs or a <header> etc.) -->
      <div class="table-header">
        <div class="header-cell">{{ $t('SALES.EMAIL.CREATED_AT') }}</div>
        <div class="header-cell">{{ $t('SALES.EMAIL.TO_EMAIL') }}</div>
        <div class="header-cell">{{ $t('SALES.ORG_NUMBER') }}</div>
        <div class="header-cell">{{ $t('SALES.EMAIL.AGENT_ID') }}</div>
        <div class="header-cell">{{ $t('SALES.EMAIL.DIRECTION') }}</div>
        <div class="header-cell actions-column"></div>
      </div>

      <!-- Transition-group to animate new items. 'fade-down' is an example transition name. -->
      <transition-group name="slide-down" tag="div">
        <div
          v-for="item in items"
          :key="item.id"
          class="table-row fade-in"
        >
          <!-- Column: created_at, formatted -->
          <div class="table-cell">
            {{ formatDate(item.created_at) }}
          </div>

          <!-- Column: to_email -->
          <div class="table-cell">
            {{ item.to_email }}
          </div>

          <!-- Column: item.sale.org_number -->
          <div class="table-cell">
            {{ item.sale?.org_number }}
          </div>

          <!-- Column: item.agent name -->
          <div class="table-cell">
            {{ item.agent?.name || 'Ingen' }}
          </div>

          <!-- Column: direction -->
          <div class="table-cell">
            {{ $t('SALES.EMAIL.DIRECTIONS.' + item.direction) }}
          </div>

          <!-- Column: actions -->
          <div class="table-cell actions-column">
            <a href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="select_item_clicked(item)">
              <inline-svg src="/assets/svg/Write.svg" />
            </a>
            
          </div>
        </div>
      </transition-group>
    </div>

  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

import Confirm from '@/view/components/Confirm.vue';
import axios from 'axios';
import dayjs from 'dayjs';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js';
import SalesStateEditorModal from './SalesStateEditorModal.vue';

import { randomstr } from '@/core/services/deviceId';

export default {
  name: 'SalesStateTable',
  components: {
    Multiselect,
    Confirm,
    SalesStateEditorModal,
  },
  mixins: [ toasts ],
  computed: {

    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings', 'currentUser', 'isTHS']),
    
    sales_status_options_mult() {
      return this.sales_status_options.map(item => ({ label: item.text, value: item.value }));
    },
    direction_options_mult() {
      return this.direction_options.map(item => ({ label: item.text, value: item.value }));
    },
    direction_options() {
      return [
        { text: this.$t('SALES.EMAIL.DIRECTIONS.INBOUND'), value: 'INBOUND' },
        { text: this.$t('SALES.EMAIL.DIRECTIONS.OUTGOING'), value: 'OUTGOING' },
      ]
    },
    sales_status_options() {
      return [
        { text: this.$t('SALES.SALES_STATUSES.PROSPECT'), value: 'PROSPECT' },
        { text: this.$t('SALES.SALES_STATUSES.DEAD'), value: 'DEAD' },
        { text: this.$t('SALES.SALES_STATUSES.NOT_ANSWER'), value: 'NOT_ANSWER' },
        { text: this.$t('SALES.SALES_STATUSES.DECLINED'), value: 'DECLINED' },
        { text: this.$t('SALES.SALES_STATUSES.OPPORTUNITY'), value: 'OPPORTUNITY' },
        { text: this.$t('SALES.SALES_STATUSES.CLOSING'), value: 'CLOSING' },
        { text: this.$t('SALES.SALES_STATUSES.SIGNED'), value: 'SIGNED' },
        { text: this.$t('SALES.SALES_STATUSES.LIVE'), value: 'LIVE' },
      ]
    },
    company_type_options_mult() {
      return this.company_type_options.map(item => ({ label: item.text, value: item.value }));
    },
    company_type_options() {
      return [
        { text: this.$t('SALES.SALES_COMPANY_TYPES.IDEELL'), value: 'IDEELL' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.TROSSAMFUND'), value: 'TROSSAMFUND' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.STUDENT'), value: 'STUDENT' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.AKASSA'), value: 'AKASSA' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.EKONOMISK_FORENING'), value: 'EKONOMISK_FORENING' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.OTHER_STIFTELSER'), value: 'OTHER_STIFTELSER' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.SAMFALLIGHET'), value: 'SAMFALLIGHET' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.UNKNOWN'), value: 'UNKNOWN' },
      ]
    },
    decline_reason_options() {
      return [
        { text: this.$t('SALES.DECLINE_REASONS.BAD_LEAD'), value: 'BAD_LEAD' },
        { text: this.$t('SALES.DECLINE_REASONS.NO_MONEY'), value: 'NO_MONEY' },
        { text: this.$t('SALES.DECLINE_REASONS.TOO_EXPENSIVE'), value: 'TOO_EXPENSIVE' },
        { text: this.$t('SALES.DECLINE_REASONS.NO_INTEREST'), value: 'NO_INTEREST' },
        { text: this.$t('SALES.DECLINE_REASONS.SATISFIED_COMPETITOR'), value: 'SATISFIED_COMPETITOR' },
        { text: this.$t('SALES.DECLINE_REASONS.LACKS_FEATURES'), value: 'LACKS_FEATURES' },
        { text: this.$t('SALES.DECLINE_REASONS.UNKNOWN'), value: 'UNKNOWN' },
      ]
    }
  },
  props: [],
  emits: ['on_select_item', 'on_delete_item'],
  mounted() {
    this.load_users_for_company(this.currentCompanyId);
    this.load_agents_for_company(this.currentCompanyId);

    setInterval(()=>{
      this.poll_for_new_rows();
    }, 2000);
  },
  data() {
    return {
      last_id: 0,
      items: [],
      selected_item: {},
      agent_options: [],
      filters: {},
      company_type_option: null,
      total_rows: 0,
      current_page: 1,
      per_page: 100,
      search: null,
      user_options: []
    };
  },
  watch: {
    filters: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        
      },
    },
  },
  methods: {

    formatDate(dt) {
      return dayjs(dt).format('YYYY-MM-DD HH:mm:ss');
    },

    poll_for_new_rows() {
      
      // We'll call the server for the updated list
      // or specifically fetch newly created items
      axios.get(`/sales/states/${this.currentCompanyId}?since_id=${this.last_id}`)
        .then(res => {
          if (res.status === 200 && Array.isArray(res.data)) {
            // Suppose the server returns new items that are not in the local array
            this.prepend_new_items(res.data)
          }
        })
        .catch(err => console.error('poll_for_new_rows error', err))
    },

    prepend_new_items(newRecords) {
      // For each record, if it's not in "items", unshift it
      // This is simplistic; typically you'd check ID or a timestamp
      newRecords.forEach(record => {
        const exists = this.items.find(i => i.id === record.id)
        if (!exists) {
          this.last_id = record.id;
          this.items.unshift(record) // add to the front
        }
      })
    },


    updated_agent_email(item) {},
    created_agent_email(item) {},
    
    
    async load_users_for_company(company_id) {
      this.user_options = [];
      
      const res = await axios.get(`/access/users?company_id=${company_id}`);

      if (res.status === 200) {
        this.user_options = res.data.map((item) => ({ text: item.email, value: item.user_id }));
        this.user_options.unshift({ text: this.$t('COMMON.ALL'), value: null });
      }
    },

    async load_agents_for_company(company_id) {
      this.agent_options = [];
      
      const res = await axios.get(`/sales/agents/company/${company_id}`);

      if (res.status === 200) {
        this.agent_options = res.data.map((item) => ({ text: item.name, value: item.id }));
        this.agent_options.unshift({ text: this.$t('COMMON.ALL'), value: null });
      }
    },

    create_sales_clicked() {
      this.$emit('create_clicked');
    },

    select_item_clicked(item) {
      this.selected_item = item;
      this.$refs['sales-state-modal'].show();
      this.$emit('on_select_item', id);
    },
    async delete_item_clicked(id) {

      await axios.delete(`/sales/email/${id}`);
      
      this.$emit('on_delete_item', id);
    },


    get_filters_string() {
      let url_filters = '';

      if (this.filters.text) {
        url_filters += `&text=${encodeURIComponent(this.filters.text)}`;
      }

      if (this.filters.created_from) {
        url_filters += `&created_from=${this.filters.created_from}`;
      }

      if (this.filters.created_to) {
        url_filters += `&created_to=${this.filters.created_to}`;
      }

      if (this.filters.directions) {
        url_filters += `&statuses=${encodeURIComponent('[' + this.filters.directions.map(x => `"${x}"`).join(',') + ']')}`;
      }

      if (this.filters.types) {
        url_filters += `&types=${encodeURIComponent('[' + this.filters.types.map(x => `"${x}"`).join(',') + ']')}`;
      }

      if (this.filters.agent_id) {
        url_filters += `&agent_id=${this.filters.agent_id}`;
      }
      
      return url_filters;
    },

    get_pagination(callback) {
      console.log('get_pagination');
      axios
        .get(`/sales/emails?page=${this.current_page}&limit=${this.per_page}${this.get_filters_string()}`)
        .then(res => {
          if (res.status !== 200) {
            console.error('pagination: invalid status', res.status);
            return;
          }

          if (callback) {
            callback(res.data || []);
          }
        })
        .catch(err => {
          console.error('pagination error', err);
        });
    },

    get_count() {
      axios
        .get(`/sales/emails?${this.get_filters_string()}`)
        .then(res => {
          if (res.status !== 200) {
            console.error('pagination count: invalid status', res.status);
            return;
          }

          this.total_rows = res.data.count;

        })
        .catch(err => {
          console.error('pagination count error', err);
        });
    },

    ajax_pagination: function(ctx, callback) {
      const vm = this;
      let params = {
        pageNo: ctx.current_page,
        pageSize: ctx.per_page,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };

      this.get_count();
      this.get_pagination(callback);

      return null;
    },

    
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_table.scss";
@import "@/assets/sass/components/forms/_common_modals.scss";
</style>

<style scoped>
/* Basic styles for our custom table layout */

.sales-agent-emails {
  padding: 1rem;
}

.info-bar {
  margin: 1rem 0;
}

.custom-table-container {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

/* Table Header Row */
.table-header {
  display: flex;
  background-color: #f0f0f0;
  font-weight: bold;
}

.header-cell {
  padding: 8px;
  flex: 1; 
  border-right: 1px solid #ddd;
}
.header-cell:last-child {
  border-right: none;
}

.table-row {
  display: flex;
  border-bottom: 1px solid #eee;
}
.table-row:last-child {
  border-bottom: none;
}

.table-cell {
  flex: 1;
  padding: 8px;
  border-right: 1px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-cell:last-child {
  border-right: none;
  text-align: right;
}

/* Actions column narrower */
.actions-column {
  flex: 0 0 80px;
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.table-striped .table-row:nth-of-type(even){
  background-color: #eff4f9;
}

</style>