<template>
  <div class="sales-editor">

    <SalesContactPersonModal
      ref="sales-contact-modal" 
      :contact="selected_contact"
      :prospect="local_item"
      @created="contact_person_created"
      @call_clicked="contact_person_call"
      @email_clicked="contact_person_email"
    />

    <b-modal ref="call-result-modal" hide-footer>
      <div>
        <!-- Call Status Buttons -->
        <div class="call-status-buttons mt-4">
        
        <div class="button-row">
          <a
            href="#"
            class="btn btn-danger font-weight-bolder font-size-sm ml-2"
            @click.prevent="handleCallStatus('DISCONNECTED')"
            >{{ $t('SALES.SALES_CALL_RESULTS.DISCONNECTED') }}</a
          >
          <a
            href="#"
            class="btn btn-warning font-weight-bolder font-size-sm ml-2"
            @click.prevent="handleCallStatus('VOICEMAIL')"
            >{{ $t('SALES.SALES_CALL_RESULTS.VOICEMAIL') }}</a
          >
          <a
            href="#"
            class="btn btn-warning font-weight-bolder font-size-sm ml-2"
            @click.prevent="handleCallStatus('NO_ANSWER')"
            >{{ $t('SALES.SALES_CALL_RESULTS.NO_ANSWER') }}</a
          >
          <a
            href="#"
            class="btn btn-warning font-weight-bolder font-size-sm ml-2"
            @click.prevent="handleCallStatus('HUNG_UP')"
            >{{ $t('SALES.SALES_CALL_RESULTS.HUNG_UP') }}</a
          >
        </div>

        <div class="button-row">
          <a
            href="#"
            class="btn btn-info font-weight-bolder font-size-sm ml-2"
            @click.prevent="handleCallStatus('NO_INTEREST')"
            >{{ $t('SALES.SALES_CALL_RESULTS.NO_INTEREST') }}</a
          >
          <a
            href="#"
            class="btn btn-info font-weight-bolder font-size-sm ml-2"
            @click.prevent="handleCallStatus('CALL_LATER')"
            >{{ $t('SALES.SALES_CALL_RESULTS.CALL_LATER') }}</a
          >

          <a
            href="#"
            class="btn btn-danger font-weight-bolder font-size-sm ml-2"
            @click.prevent="handleCallStatus('TALK_NO_CALL')"
            >{{ $t('SALES.SALES_CALL_RESULTS.TALK_NO_CALL') }}</a
          >

        </div>
        <div class="button-row">
        
          <a
            href="#"
            class="btn btn-success font-weight-bolder font-size-sm ml-2"
            @click.prevent="handleCallStatus('TALK_CALL_AGAIN')"
            >{{ $t('SALES.SALES_CALL_RESULTS.TALK_CALL_AGAIN') }}</a
          >
        </div>
        
      </div>

      <!-- Decline Reason (Conditional) -->
      <memlist-select-input2
        v-if="local_item.status === 'DECLINED'"
        id="decline_reason"
        name="decline_reason"
        :title="$t('SALES.DECLINE_REASON')"
        layout="vertical"
        v-model="local_item.decline_reason"
        :items="decline_reason_options"
      />
      </div>
      
      <div>
        <p>{{ $t('SALES.CALL_LATER_INFO')}}</p>
      
        <memlist-date-picker
          :title="$t('SALES.SNOOZE_UNTIL')"
          v-model="local_item.snooze_until"
        >
        </memlist-date-picker>

        <memlist-select-input2
          class="mt-8"
          id="decline_reason"
          name="decline_reason"
          :title="$t('SALES.DECLINE_REASON')"
          layout="vertical"
          v-model="local_item.decline_reason"
          :items="decline_reason_options"
        />
        
        <memlist-text-input
          class="mt-8"
          id="competitor"
          name="competitor"
          :title="$t('SALES.COMPETITOR')"
          layout="vertical"
          v-model="local_item.competitor"
          type="text"
        />

        <memlist-date-picker
          class="mt-8"
          :title="$t('SALES.LOCKED_UNTIL')"
          v-model="local_item.locked_until"
        >
        </memlist-date-picker>
        
      </div>

      <div class="mt-4">
        <a
          v-if="call.call_result !== 'CALL_LATER' && call.call_result !== 'TALK_CALL_AGAIN'"
          href="#"
          class="btn btn-outline-danger font-weight-bolder font-size-sm mr-2 my-2"
          @click.prevent="delete_prospect"
          ><i class="menu-icon flaticon2-trash" style="font-size: 1.0em;"></i>{{$t('SALES.FORGET_PROSPECT')}}</a
        >

        <a
          href="#"
          class="btn btn-outline-primary font-weight-bolder font-size-sm mr-2 my-2"
          @click.prevent="save_prospect"
          ><i class="menu-icon flaticon2-next" style="font-size: 1.0em;"></i>{{$t('COMMON.SAVE')}}</a
        >
      </div>
      
    </b-modal>

    <form @submit.prevent="on_save_clicked">
      <div class="tabs">
        <ul class="tab-list">
          <li :class="{ active: activeTab === 0 }" @click="activeTab = 0">{{ $t('SALES.COLD_CALL') }}</li>
          <li :class="{ active: activeTab === 1 }" @click="activeTab = 1">{{ $t('SALES.EMAILS') }}</li>
          <li :class="{ active: activeTab === 2 }" @click="activeTab = 2">{{ $t('SALES.OFFER') }}</li>
          <li :class="{ active: activeTab === 3 }" @click="activeTab = 3">{{ $t('SALES.ADVANCED') }}</li>
        </ul>
        <div class="tab-content">
          <!-- Basic Tab Content -->
          <div v-show="activeTab === 0">
            <div class="flex-main-container">
              <div class="flex-first">
                <!-- Basic Information -->
                <div class="form-section mt-8">
                  <div class="flex-container">
                    <a
                      href="#"
                      class="btn btn-outline-danger font-weight-bolder font-size-sm mr-2 my-2"
                      @click.prevent="delete_prospect"
                      ><i class="menu-icon flaticon2-trash" style="font-size: 1.0em;"></i>{{$t('SALES.FORGET_PROSPECT')}}</a
                    >
                    
                    <a
                      v-if="(isTHS && local_item.user_id !== currentUser.user_id) || !local_item.user_id"
                      href="#"
                      class="btn btn-outline-primary font-weight-bolder font-size-sm mr-2"
                      @click.prevent="assign_self"
                      ><i class="mdi mdi-atom" aria-hidden="true"></i> {{$t('SALES.ASSIGN_SELF')}}</a
                    >
                    <a
                      v-if="local_item.openai_status === 'NONE'"
                      href="#"
                      :style="style_contacts"
                      class="btn btn-outline-primary font-weight-bolder font-size-sm mr-2"
                      @click.prevent="find_contacts"
                      ><i class="mdi mdi-assistant" aria-hidden="true"></i> {{ local_item.contacts ? local_item.contacts.length : 0 }} {{$t('SALES.SEARCH_OPENAI')}}</a
                    >

                    <div v-if="local_item.openai_status === 'STARTED'" class="ml-2">
                      <b-spinner small type="grow"></b-spinner>
                    </div>
                    
                  </div>

                  
                  <div class="flex-row">

                    <memlist-text-input
                      v-if="local_item.user_id === currentUser.user_id"
                      id="user_id"
                      name="user_id"
                      class="flex-child"
                      :title="$t('SALES.USER_ID')"
                      layout="vertical"
                      v-model="currentUser.email"
                      :required="true"
                    />

                    <memlist-text-input
                      id="name"
                      name="name"
                      class="flex-child"
                      :title="$t('SALES.NAME')"
                      layout="vertical"
                      v-model="local_item.name"
                      :required="true"
                    />
                    <memlist-text-input
                      id="org_number"
                      name="org_number"
                      class="flex-child"
                      :title="$t('SALES.ORG_NUMBER')"
                      layout="vertical"
                      v-model="local_item.org_number"
                    />
                  </div>

                  <memlist-textarea
                    id="description"
                    name="description"
                    :title="$t('SALES.DESCRIPTION')"
                    layout="vertical"
                    v-model="local_item.description"
                    :autoexpand="true"
                    type="textarea"
                  />

                  <div class="form-group d-flex align-items-center">
                    <memlist-text-input
                      class="mt-4"
                      id="website1"
                      name="website1"
                      :title="$t('SALES.WEBSITE1')"
                      layout="vertical"
                      v-model="local_item.website1"
                      type="text"
                      style="width: 500px;"
                    />
                    
                    <div style="margin-top: 48px; margin-left: 16px;" v-if="is_valid_web(local_item.website1)"><a href="" @click.prevent="visit(local_item.website1)">{{ $t('SALES.VISIT') }}</a></div>
                    
                  </div>

                  <h4 class="mt-4">{{ $t('SALES.MADE_CALLS') }}</h4>
                  <table class="email-list">
                    <thead>
                      <tr>
                        <th>{{ $t('SALES.PHONE1') }}</th>
                        <th>{{ $t('SALES.SALES_CALL_RESULT') }}</th>
                        <th>{{ $t('SALES.CREATED_AT') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(call, index) in local_item.calls" :key="index">
                        <td>{{ call.phone }}</td>
                        <td>{{ call_result_to_text(call.call_result) }}</td>
                        <td>{{ format_date(call.created_at) }}</td>
                      </tr>
                    </tbody>
                  </table>


                  

                  <!-- Notes -->
                  <memlist-textarea
                    id="notes"
                    name="notes"
                    :title="$t('SALES.NOTES')"
                    layout="vertical"
                    v-model="local_item.notes"
                    :autoexpand="true"
                    type="textarea"
                  />

                  <div class="form-group d-flex align-items-center">
                    <memlist-text-input
                      id="contact_email1"
                      name="contact_email1"
                      style="width: 328px;"
                      :title="$t('SALES.CONTACT_EMAIL_1')"
                      layout="vertical"
                      v-model="local_item.contact_email1"
                      type="email"
                    />
                    <memlist-select-input2
                      id="sendout_template"
                      name="sendout_template"
                      :title="$t('SALES.SENDOUT_TEMPLATE')"
                      layout="vertical"
                      v-model="sendout_template"
                      :items="sendout_template_type_options"
                      :required="true"
                    />
                    <a
                      href="#"
                      style="margin-top: 40px;"
                      class="btn btn-outline-primary font-weight-bolder font-size-sm ml-2"
                      @click.prevent="send_info_email"
                      ><i class="menu-icon flaticon2-email" style="font-size: 1.2em;"></i>{{ $t('SALES.SEND_EMAIL') }}</a
                    >
                  </div>
                </div>
              
              </div>
              <div class="flex-right">

                <a
                  href="#"
                  class="btn btn-outline-primary font-weight-bolder font-size-sm mr-2 mb-2"
                  @click.prevent="create_contact"
                  ><i class="mdi mdi-plus" aria-hidden="true"></i> {{$t('SALES.CREATE_CONTACT')}}</a
                >

                <div class="contact-grid">
                  <template v-for="(contact, index) in local_item.contacts" >
                    <SalesContactPerson
                      class="contact-item"
                      :key="index"
                      :contact="contact"
                      :prospect="local_item"
                      @deleted="deleted_contact"
                      @call_clicked="contact_person_call"
                      @email_clicked="contact_person_email"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>

          
          <div v-show="activeTab === 1">

            <h4>{{ $t('SALES.SENT_EMAILS') }}</h4>
            <table class="email-list">
              <thead>
                <tr>
                  <th>{{ $t('SALES.TO_EMAIL') }}</th>
                  <th>{{ $t('SALES.SUBJECT') }}</th>
                  <th>{{ $t('SALES.SENT_AT') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(email, index) in local_item.emails" :key="index">
                  <td>{{ email.to_email }}</td>
                  <td>{{ email.subject }}</td>
                  <td>{{ email.created_at }}</td>
                </tr>
              </tbody>
            </table>

            <p class="mt-8">{{ $t('FORM.HTML') }}</p>
            <tiptap-vuetify
              data-app
              v-model="local_item.html"
              :extensions="extensions"
            />


          </div>
          <div v-show="activeTab === 2">
            <!-- Financial Information -->
            <div class="form-section mt-8">
              <h4 class="my-4">{{ $t('SALES.FINANCIAL_INFORMATION') }}</h4>
              
              <div class="flex-row">
                <!-- Number of Board Members -->
                <memlist-text-input
                  id="num_board"
                  name="num_board"
                  :title="$t('SALES.NUM_BOARD')"
                  layout="vertical"
                  v-model="local_item.num_board"
                  type="text"
                />

                <!-- Number of Members -->
                <memlist-text-input
                  id="num_members"
                  name="num_members"
                  :title="$t('SALES.NUM_MEMBERS')"
                  layout="vertical"
                  v-model="local_item.num_members"
                  type="text"
                />
              
              </div>

              <div class="flex-row">
                <!-- Minimum Fee -->
                <memlist-text-input
                  id="min_fee"
                  name="min_fee"
                  :title="$t('SALES.MIN_FEE')"
                  layout="vertical"
                  v-model="local_item.min_fee"
                  type="text"
                />

                <!-- Maximum Fee -->
                <memlist-text-input
                  id="max_fee"
                  name="max_fee"
                  :title="$t('SALES.MAX_FEE')"
                  layout="vertical"
                  v-model="local_item.max_fee"
                  type="text"
                />

                <memlist-text-input
                  id="avg_fee"
                  name="avg_fee"
                  :disabled="true"
                  :title="$t('SALES.AVG_FEE')"
                  layout="vertical"
                  v-model="avg_fee"
                  type="text"
                />
              </div>

              <div class="flex-row">
                <memlist-text-input
                  id="calculated_per_year"
                  name="calculated_per_year"
                  :disabled="true"
                  :title="$t('SALES.CALCULATED_PER_YEAR')"
                  layout="vertical"
                  v-model="calculated_per_year"
                  type="text"
                />

                <memlist-text-input
                  id="discount_sek"
                  name="discount_sek"
                  :title="$t('SALES.DISCOUNT_SEK')"
                  layout="vertical"
                  v-model="local_item.discount_sek"
                  type="text"
                />

                <memlist-text-input
                  id="final_calc_per_year"
                  name="final_calc_per_year"
                  :title="$t('SALES.FINAL_CALC_PER_YEAR')"
                  layout="vertical"
                  :disabled="true"
                  v-model="final_calc_per_year"
                  type="text"
                />
              </div>

              <div class="flex-row">
                <!-- Offered Per Year -->
                <memlist-text-input
                  id="offered_per_year"
                  name="offered_per_year"
                  :title="$t('SALES.OFFERED_PER_YEAR')"
                  layout="vertical"
                  v-model="local_item.offered_per_year"
                  type="text"
                />

                <!-- Accepted Per Year -->
                <memlist-text-input
                  id="accepted_per_year"
                  name="accepted_per_year"
                  :title="$t('SALES.ACCEPTED_PER_YEAR')"
                  layout="vertical"
                  v-model="local_item.accepted_per_year"
                  type="text"
                />
              </div>

              
            </div>

            <!-- Preferences -->
            <div class="form-section mt-8">
              <h4>{{ $t('SALES.PREFERENCES') }}</h4>
              
              <!-- Want Donation -->
              <memlist-checkbox
                id="want_donation"
                name="want_donation"
                :text="$t('SALES.WANT_DONATION')"
                v-model="local_item.want_donation"
              />

              <!-- Want Autogiro -->
              <memlist-checkbox
                id="want_autogiro"
                name="want_autogiro"
                :text="$t('SALES.WANT_AUTOGIRO')"
                v-model="local_item.want_autogiro"
              />

              <!-- Want Events -->
              <memlist-checkbox
                id="want_events"
                name="want_events"
                :text="$t('SALES.WANT_EVENTS')"
                v-model="local_item.want_events"
              />

              <!-- Want BankID -->
              <memlist-checkbox
                id="want_bankid"
                name="want_bankid"
                :text="$t('SALES.WANT_BANKID')"
                v-model="local_item.want_bankid"
              />

              <!-- Want SMS -->
              <memlist-checkbox
                id="want_sms"
                name="want_sms"
                :text="$t('SALES.WANT_SMS')"
                v-model="local_item.want_sms"
              />

              <!-- Want Sendouts -->
              <memlist-checkbox
                id="want_sendouts"
                name="want_sendouts"
                :text="$t('SALES.WANT_SENDOUTS')"
                v-model="local_item.want_sendouts"
              />

              <!-- Want Swish -->
              <memlist-checkbox
                id="want_swish"
                name="want_swish"
                :text="$t('SALES.WANT_SWISH')"
                v-model="local_item.want_swish"
              />

              <!-- Want Stripe -->
              <memlist-checkbox
                id="want_stripe"
                name="want_stripe"
                :text="$t('SALES.WANT_STRIPE')"
                v-model="local_item.want_stripe"
              />

              <!-- Want Spar -->
              <memlist-checkbox
                id="want_spar"
                name="want_spar"
                :text="$t('SALES.WANT_SPAR')"
                v-model="local_item.want_spar"
              />

              <!-- Want Fortnox -->
              <memlist-checkbox
                id="want_fortnox"
                name="want_fortnox"
                :text="$t('SALES.WANT_FORTNOX')"
                v-model="local_item.want_fortnox"
              />

              <!-- Want MemRequest -->
              <memlist-checkbox
                id="want_memrequest"
                name="want_memrequest"
                :text="$t('SALES.WANT_MEMREQUEST')"
                v-model="local_item.want_memrequest"
              />

              <!-- Want Invoice -->
              <memlist-checkbox
                id="want_invoice"
                name="want_invoice"
                :text="$t('SALES.WANT_INVOICE')"
                v-model="local_item.want_invoice"
              />

              <!-- Want App -->
              <memlist-checkbox
                id="want_app"
                name="want_app"
                :text="$t('SALES.WANT_APP')"
                v-model="local_item.want_app"
              />

              <!-- Want Grants -->
              <memlist-checkbox
                id="want_grants"
                name="want_grants"
                :text="$t('SALES.WANT_GRANTS')"
                v-model="local_item.want_grants"
              />

              <!-- Want MUCF -->
              <memlist-checkbox
                id="want_mucf"
                name="want_mucf"
                :text="$t('SALES.WANT_MUCF')"
                v-model="local_item.want_mucf"
              />
            </div>
          </div>
          
          <div v-show="activeTab === 3">
            <!-- Type -->
            <memlist-select-input2
              id="type"
              name="type"
              :title="$t('SALES.SALES_COMPANY_TYPE')"
              layout="vertical"
              v-model="local_item.type"
              :items="company_type_options"
              :required="true"
            />

            <!-- Status -->
            <memlist-select-input2
              id="status"
              name="status"
              :title="$t('SALES.SALES_STATUS')"
              layout="vertical"
              v-model="local_item.status"
              :items="sales_status_options"
              :required="true"
            />            

            <!-- Created At (Disabled) -->
            <memlist-text-input
              id="created_at"
              name="created_at"
              :title="$t('SALES.CREATED_AT')"
              layout="vertical"
              :disabled="true"
              v-model="local_item.created_at"
            />

            <!-- Called At -->
            <memlist-text-input
              id="called_at"
              name="called_at"
              :title="$t('SALES.CALLED_AT')"
              layout="vertical"
              v-model="local_item.called_at"
              type="datetime-local"
            />

            <!-- Snooze Until -->
            <memlist-text-input
              id="snooze_until"
              name="snooze_until"
              :title="$t('SALES.SNOOZE_UNTIL')"
              layout="vertical"
              v-model="local_item.snooze_until"
              type="datetime-local"
            />

            <!-- Closed At -->
            <memlist-text-input
              id="closed_at"
              name="closed_at"
              :title="$t('SALES.CLOSED_AT')"
              layout="vertical"
              v-model="local_item.closed_at"
              type="datetime-local"
            />
          
            <!-- Fortnox ID -->
            <memlist-text-input
              id="fortnox_id"
              name="fortnox_id"
              :title="$t('SALES.FORTNOX_ID')"
              layout="vertical"
              :disabled="true"
              v-model="local_item.fortnox_id"
            />
        
            <!-- Salesperson Selection -->
            <memlist-select-input2
              id="user_id"
              name="user_id"
              :title="$t('SALES.SALESPERSON')"
              layout="vertical"
              v-model="local_item.user_id"
              :items="salespersons"
              :required="false"
            />
          </div>
        </div>
      </div>

      <!-- Error Popup -->
      <ErrorPopup :error="error_popup_message" />


      <!-- Action Buttons -->
      <RightModalSaveAndCloseButtons
        class="mt-4"
        :text="$t('COMMON.SAVE')"
        :spin="is_saving"
        @clicked="on_save_clicked"
        @close="$emit('cancel')"
        ref="saveButton"
      />
    </form>
  </div>
</template>

<script>
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import ErrorPopup from '@/view/components/ErrorPopup.vue';
import axios from 'axios';
import dayjs from 'dayjs';
import SalesContactPerson from '@/view/pages/ml/ths/sales/SalesContactPerson.vue';
import SalesContactPersonModal from '@/view/pages/ml/ths/sales/SalesContactPersonModal.vue';


import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';
import { TiptapVuetify, Image, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';


export default {
  name: 'SalesEditor',
  components: {
    TiptapVuetify,
    ErrorPopup,
    RightModalSaveAndCloseButtons,
    SalesContactPerson,
    SalesContactPersonModal,
  },
  props: {
    sale_id: {
      type: Number,
      required: false,
      default: null,
    },
    current_call_id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  watch: {
    sale_id: {
      handler(newVal) {

        if (this.sale_id) {
          this.fetchSalesRecord();
        }
        
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      selected_contact: null,
      interval_id: null,
      sendout_template: 'SALES_INFO_MAIL',
      activeTab: 0,
      showCallStatusButtons: false,
      local_item: {
        id: 0,
        created_at: '',
        name: '',
        num_board: 0,
        num_members: 0,
        min_fee: 0,
        max_fee: 0,
        status: 'PROSPECT',
        type: 'IDEELL',
        is_emailed: false,
        is_called: false,
        is_snoozed: false,
        want_donation: false,
        want_autogiro: false,
        want_events: false,
        want_bankid: false,
        want_sms: false,
        want_sendouts: false,
        want_swish: false,
        want_stripe: false,
        want_spar: false,
        want_fortnox: false,
        want_memrequest: false,
        want_invoice: false,
        want_app: false,
        want_grants: false,
        want_mucf: false,
        decline_reason: null
      },
      extensions: [
        Image,
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],

      refresh: 0,

      error_popup_message: null,
      is_saving: false,
      
      salespersons: [],

      call: {},
    };
  },
  mixins: [ toasts ],
  computed: {
    final_calc_per_year() {
      return (Number(this.calculated_per_year) * 0.1) - Number(this.local_item.discount_sek);
    },
    avg_fee() {
      let fee = 0;
      let min_fee = Number(this.local_item.min_fee);
      let max_fee = Number(this.local_item.max_fee);

      if (min_fee > 0 && max_fee > 0) {
        fee = (max_fee - min_fee) / 2 + min_fee;
      }
      else {
        fee = min_fee > 0 ? min_fee : max_fee;
      }

      return fee;
    },
    calculated_per_year() {
      return (this.local_item.num_members * this.avg_fee);
    },
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings', 'currentUser', 'isTHS']),
    style_contacts() {
      this.refresh;

      switch (this.local_item.openai_status) {
        case 'NONE':
        case 'STARTED':
          return {
            'border-color': 'gray',
            'color': 'gray'
          }
        case 'FAILED':
          return {
            'border-color': 'rgb(255, 68, 68)',
            'color': 'rgb(255, 68, 68)'
          }
        case 'ENDED': {
          return {}
        }
      }

      return {
        'border-color': 'white'
      }
    },

    sendout_template_type_options() {

      const base = [
        { text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.SALES_INFO_MAIL'), value: 'SALES_INFO_MAIL' },
        { text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.SALES_INVITE_MEETING'), value: 'SALES_INVITE_MEETING' },
        { text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.SALES_SIGN_CONTRACT'), value: 'SALES_SIGN_CONTRACT' },
        { text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.SALES_FOLLOW_UP'), value: 'SALES_FOLLOW_UP' },
        { text: this.$t('TEMPLATE.SENDOUT_TEMPLATES.SALES_WELCOME_AFTER_SIGN'), value: 'SALES_WELCOME_AFTER_SIGN' },
      ];

      return base;
    },
    sales_status_options() {
      return [
        { text: this.$t('SALES.SALES_STATUSES.PROSPECT'), value: 'PROSPECT' },
        { text: this.$t('SALES.SALES_STATUSES.DEAD'), value: 'DEAD' },
        { text: this.$t('SALES.SALES_STATUSES.NOT_ANSWER'), value: 'NOT_ANSWER' },
        { text: this.$t('SALES.SALES_STATUSES.DECLINED'), value: 'DECLINED' },
        { text: this.$t('SALES.SALES_STATUSES.OPPORTUNITY'), value: 'OPPORTUNITY' },
        { text: this.$t('SALES.SALES_STATUSES.CLOSING'), value: 'CLOSING' },
        { text: this.$t('SALES.SALES_STATUSES.SIGNED'), value: 'SIGNED' },
        { text: this.$t('SALES.SALES_STATUSES.LIVE'), value: 'LIVE' },
      ]
    },
    company_type_options() {
      return [
        { text: this.$t('SALES.SALES_COMPANY_TYPES.IDEELL'), value: 'IDEELL' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.TROSSAMFUND'), value: 'TROSSAMFUND' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.STUDENT'), value: 'STUDENT' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.AKASSA'), value: 'AKASSA' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.EKONOMISK_FORENING'), value: 'EKONOMISK_FORENING' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.OTHER_STIFTELSER'), value: 'OTHER_STIFTELSER' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.SAMFALLIGHET'), value: 'SAMFALLIGHET' },
        { text: this.$t('SALES.SALES_COMPANY_TYPES.UNKNOWN'), value: 'UNKNOWN' },
      ]
    },
    decline_reason_options() {
      return [
        { text: this.$t('SALES.DECLINE_REASONS.BAD_LEAD'), value: 'BAD_LEAD' },
        { text: this.$t('SALES.DECLINE_REASONS.NO_MONEY'), value: 'NO_MONEY' },
        { text: this.$t('SALES.DECLINE_REASONS.TOO_EXPENSIVE'), value: 'TOO_EXPENSIVE' },
        { text: this.$t('SALES.DECLINE_REASONS.NO_INTEREST'), value: 'NO_INTEREST' },
        { text: this.$t('SALES.DECLINE_REASONS.SATISFIED_COMPETITOR'), value: 'SATISFIED_COMPETITOR' },
        { text: this.$t('SALES.DECLINE_REASONS.LACKS_FEATURES'), value: 'LACKS_FEATURES' },
        { text: this.$t('SALES.DECLINE_REASONS.UNKNOWN'), value: 'UNKNOWN' },
      ]
    }
  },
  mounted() {

    this.interval_id = setInterval(() => {
      this.refresh++;
    }, 1000);

    if (!this.sale_id) {
      localStorage.removeItem('call_id');
      localStorage.removeItem('sale_id');
      localStorage.removeItem('call_status');
    }

    this.fetchSalespersons();
    
    this.resumeCall();
  },
  methods: {
    contact_person_call(data, phone) {
      console.log('contact_person_call', data, phone);
      this.perform_call(phone);

      this.$refs['call-result-modal'].show();
    },

    contact_person_email(data, email) {},
    
    contact_person_created(data) {
      this.selected_contact = data;

      this.local_item.contacts.push(data);
      this.local_item = { ...this.local_item };
      this.toastr('success', this.$t('COMMON.OK'), this.$t('SALES.CONTACT_CREATED'));
    },
    deleted_contact(id) {
      this.local_item.contacts = this.local_item.contacts.filter(item => item.id !== id);
      this.local_item = { ...this.local_item };
      this.toastr('success', this.$t('COMMON.OK'), this.$t('SALES.CONTACT_DELETED'));
    },
    create_contact() {
      this.selected_contact = {};
      this.$refs['sales-contact-modal'].show();
    },
    async find_contacts() {
      if (this.local_item.openai_status !== 'NONE') {
        return;
      }

      if (this.local_item.search_status === 'NONE') {
        const res = await axios.post(`/sales/${this.local_item.id}/search`, {});
        if (res.status !== 201) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SALES.UNABLE_TO_SEARCH'));
          return;
        }

        this.local_item = { ...res.data };
      }

      this.$emit('updated', { ...this.local_item, openai_status: 'STARTED' }, false, false);

      const res = await axios.post(`/sales/${this.local_item.id}/openai`, {});

      if (res.status === 201) {
        const res2 = await axios.get(`/sales/${this.local_item.id}`);

        this.$emit('updated', res2.data);
      }
      else {
        this.local_item.openai_status = 'FAILED';
        this.local_item = { ...this.local_item };
        this.$emit('updated', { ...this.local_item, openai_status: 'FAILED' });
      }
    },
    async assign_self() {
      const res = await axios.put(`/sales/${this.local_item.id}`, { user_id: this.currentUser.user_id });

      if (res.status === 200) {
        this.toastr('success', this.$t('COMMON.OK'), this.$t('SALES.ASSIGNED_TO_SELF'));
        this.local_item.user_id = this.currentUser.user_id;
        this.local_item = { ...this.local_item };
      }
      else {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SALES.UNABLE_TO_ASSIGN_SELF'));
      }
    },
    visit(web) {
      window.open(web, '_blank');
    },
    is_valid_web(web) {
      const regx = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
      return regx.test(web);
    },
    async delete_prospect() {
      this.local_item.status = 'DEAD';

      await this.save_prospect();

      this.$emit('cancel');
    },

    async save_prospect() {
      await this.on_save_clicked();

      this.call = {};

      this.$refs['call-result-modal'].hide();
    },

    
    format_date(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm');
    },

    call_result_to_text(call_result) {
      return this.$t('SALES.SALES_CALL_RESULTS.' + call_result);
    },

    async send_info_email() {
      const res = await axios.post(`/sales/${this.local_item.id}/email`, { });

      if (res.status === 201) {
        this.toastr('success', this.$t('COMMON.OK'), this.$t('SALES.EMAIL_SENT'));
      }
      else {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SALES.UNABLE_SEND_EMAIL'));
      }
    },

    async resumeCall() {
      if (this.current_call_id) {
        this.showCallStatusButtons = true;
        this.call = await axios.get(`/sales/call/${this.current_call_id}`);
      }
    },

    async perform_call(phone) {

      if (!phone) {
        return;
      }

      if (phone.indexOf('tel:') < 0) {
        phone = 'tel:' + phone;
      }

      phone = phone.replaceAll(' ', '');
      phone = phone.replaceAll('-', '');

      this.showCallStatusButtons = true;

      const res = await axios.post(`/sales/${this.local_item.id}/call`, { phone: phone });

      if (res.status === 201) {
        localStorage.setItem('call_id', res.data.id);
        localStorage.setItem('sale_id', this.local_item.id);
        localStorage.setItem('call_status', 'INITIATED');

        this.call = res.data;
        this.local_item.calls.push(this.call);

        location.href = phone;
      }

    },
    async handleCallStatus(status) {
      const ended_at = dayjs().format();
      const res = await axios.put(`/sales/call/${this.call.id}`, { call_result: status, ended_at: ended_at });

      if (res.status === 204) {
        localStorage.removeItem('call_id');
        localStorage.removeItem('sale_id');
        localStorage.removeItem('call_status');

        if (this.call && this.call.id) {
          this.call.call_result = status;
          this.call.ended_at = ended_at;

          const index = this.local_item.calls.findIndex(i => i.id === this.call.id);

          if (index >= 0) {
            this.local_item.calls[index] = this.call;
            this.local_item = { ...this.local_item };
          }
          
          this.showCallStatusButtons = false;

          this.$nextTick(() => { 
            this.$refs['call-result-modal'].show();
          });
        }
        
        
        this.toastr('success', this.$t('COMMON.OK'), this.$t('SALES.CALL_ENDED'));
      }
      else {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SALES.UNABLE_TO_END_CALL'));
      }
    },

    async get_users() {
      try {
        const res = await axios.get(`/access/users`);

        if (res.status === 200) {
          return res.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.UNABLE_GET'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.UNABLE_GET'));
      }

      return [];
    },

    /**
     * Fetches the list of salespersons from the backend.
     */
    async fetchSalespersons() {
      try {
        const users = await this.get_users();
        this.salespersons = users.map((user) => ({
          value: user.user_id,
          label: user.firstname + ' ' + user.lastname,
        }));
      } catch (error) {
        console.error('Error fetching salespersons:', error);
        this.error_popup_message = 'Failed to load salespersons.';
      }
    },

    /**
     * Fetches the existing sales record if editing.
     */
    async fetchSalesRecord() {
      if (this.sale_id) {
        try {
          const response = await axios.get(`/sales/${this.sale_id}`);
          this.local_item = response.data;
        } catch (error) {
          console.error('Error fetching sales record:', error);
          this.error_popup_message = 'Failed to load sales record.';
        }
      } else {
        // Initialize created_at with current timestamp
        this.local_item.created_at = dayjs().format('YYYY-MM-DDTHH:mm');
      }
    },

    /**
     * Handles the save button click event.
     * Creates a new sales record or updates an existing one.
     */
    async on_save_clicked() {
      this.is_saving = true;
      try {
        if (this.sale_id) {
          // Update existing record
          const response = await axios.put(`/sales/${this.sale_id}`, this.local_item);
          if (response.status === 200) {
            this.$emit('updated', this.local_item);
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SALES.UNABLE_UPDATE'));
          }
          
        } else {
          // Create new record
          const response = await axios.post('/sales', this.local_item);

          if (response.status === 201) {
            this.$emit('created', response.data);
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SALES.UNABLE_CREATE'));
          }
        }
      } catch (error) {
        console.error('Error saving sales record:', error);
        if (error.response && error.response.data && error.response.data.reason) {
          this.error_popup_message = error.response.data.reason;
        } else {
          this.error_popup_message = 'An unexpected error occurred.';
        }
      } finally {
        this.is_saving = false;
      }
    },
  },
};
</script>


<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
@import "@/assets/sass/components/forms/_memlist_tabs.scss";


.contact-grid {
  width: 512px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 8px;
}

.contact-item {
  width: 240px;
}

.flex-row {
  display: flex;
}

.flex-child {
  flex: 1;
}


.call-status-buttons {
  padding: 8px;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding-bottom: 18px;
}

.button-row {
  margin-top: 12px;
}

.btn-type2 {
  background-color: #eb9800;
  color: white;
}

.email-list {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}

.email-list th,
.email-list td {
  padding: 0.75em;
  border: 1px solid #ccc;
}

.email-list th {
  background: #f5f5f5;
  font-weight: bold;
  text-align: left;
}

.flex-main-container {
  display: flex;
  align-items: stretch;
  gap: 5px;
}

.flex-first {
  flex-grow: 1;
}

</style>