<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <!-- Add membership begin -->
      <b-form ref="form" @submit.prevent="on_submit" class="mt-8">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('PERIOD.PERIOD')">

                <b-form-select
                  @change="on_period_change"
                  v-model="local_item.period_id"
                  :options="period_options"
                  :state="validate_state({ dirty: v$['local_item']['period_id'].$dirty, error: v$['local_item']['period_id'].$error })"
                  ></b-form-select>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('COMPANY.VFDT')">

              <memlist-date-picker
                v-model="local_item.vfdt"
                :state="state_vfdt"
              >
              </memlist-date-picker>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('COMPANY.VTDT')">

              <memlist-date-picker
                v-model="local_item.vtdt"
                :state="state_vtdt"
              >
              </memlist-date-picker>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('COMPANY.COMPANY')">

                <b-form-select
                  v-model="local_item.company_id"
                  :options="company_options"
                  :state="validate_state({ dirty: v$['local_item']['company_id'].$dirty, error: v$['local_item']['company_id'].$error })"
                  >
                </b-form-select>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('MEMBERSHIP.SHOP_ITEM')">

                <b-form-select
                  v-model="local_item.shop_item_id"
                  :options="shop_items_options"
                  :state="validate_state({ dirty: v$['local_item']['shop_item_id'].$dirty, error: v$['local_item']['shop_item_id'].$error })"
                  >
                </b-form-select>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('COMMON.PAID')">

                <b-form-select
                  v-model="local_item.is_paid"
                  :options="paying_options"
                  :state="validate_state({ dirty: v$['local_item']['is_paid'].$dirty, error: v$['local_item']['is_paid'].$error })"
                  >
                </b-form-select>

            </b-form-group>
          </b-col>
        </b-row>

        <p style="margin-top: 42px;"></p>

        <b-row>
          <b-col>

            <RightModalSaveAndCloseButtons
              :text="$t('COMMON.SAVE')"
              :spin="true"
              @clicked="on_submit"
              @close="$emit('cancel')"
              ref="saveButton"
            />
          </b-col>
        </b-row>
      </b-form>


    </div>
  </div>
</template>


<script>
import { useVuelidate } from '@vuelidate/core'
import { minLength, required, email, requiredIf } from '@vuelidate/validators';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';


import axios from 'axios';
import dayjs from 'dayjs';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {

  name: 'MemberCompanyForm',

  props: ['member','item','items'],
  emits: ['created', 'updated', 'save_data', 'cancel'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons
  },

  watch: {
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };
      }
    },
    'local_item.company_id'() {
      this.get_all_shop_items(this.local_item.company_id);
    }
  },

  // --- begin vuelidate --- //
  setup () {
    return { v$: useVuelidate() }
  },

  validations () {
    return {
      local_item: {
        period_id: { required },
        vfdt: { required },
        vtdt: { required },
        company_id: { required },
        shop_item_id: { required },
        is_paid: { required }
      }
    }
  },

  // -- end vuelidate -- //

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
    state_vfdt() {
      if (!this.v$.local_item.vfdt.$dirty) {
        return null;
      }

      return dayjs(this.$v.local_item.vfdt).isValid();
    },
    state_vtdt() {
      if (!this.v$.local_item.vtdt.$dirty) {
        return null;
      }

      return dayjs(this.$v.local_item.vtdt).isValid();
    },
    disabledCreateButton() {
      return !this.local_item.company_id || !this.local_item.period_id || !this.local_item.shop_item_id || !this.local_item.vfdt || !this.local_item.vtdt || this.local_item.is_paid === null;
    }
  },


  mounted() {

    if (this.item) {
      this.local_item = { ...this.item }
    }

    this.company_options = this.companies.map((item) => ({ text: item.name, value: item.company_id }));
    this.period_options = this.periods.map((item) => ({ text: item.name, value: item.id }));

    if (this.item !== null) {
      this.get_all_shop_items(this.item.company_id);
    }
    else {
      this.get_all_shop_items(this.currentCompanyId);
    }

  },

  methods: {

    check_has_duplicates(item) {

      if (this.periods.length === 0) {
        return false;
      }

      if (this.periods.length === 1) {
        // Returns `true` if any item has the same period & company IDs
        // but has an overlapping date range with `item`.
        return this.items.some(existing => {
          return (
            // same company & period
            existing.member_company_id !== item.member_company_id &&
            existing.company_id === item.company_id &&
            existing.period_id === item.period_id &&
            // (Optional) if you want to exclude the same exact member company:
            // existing.member_company_id !== item.member_company_id &&

            // check overlap:
            // Two time-ranges [start1, end1], [start2, end2] overlap if:
            // start1 < end2 && start2 < end1
            (item.vfdt < existing.vtdt && existing.vfdt < item.vtdt)
          );
        });
      }

      const filtered = this.items.filter(i => i.member_company_id !== item.member_company_id && i.period_id === item.period_id && i.company_id === item.company_id);

      return filtered.length > 0;
    },

    validate_state(state) {
      return state.dirty ? !state.error : null;
    },

    async on_submit() {

      if (this.check_has_duplicates(this.local_item)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER_COMPANY.DUPLICATE_MEMBERSHIPS'));
        this.$refs['saveButton'].stop();
        return;
      }

      const valid = await this.v$.$validate();

      if (!valid) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.FORM_VALIDATION_FAILED'));
        this.$refs['saveButton'].stop();
        return;
      }

      if (!this.local_item.member_company_id) {
        await this.create_member_company();
      }
      else {
        await this.update_member_company();
      }

      this.$refs['saveButton'].stop();
    },

    on_period_change() {

      if (this.periods.length === 0) {
        return;
      }

      this.$nextTick(()=>{
        const period = this.periods.find((item) => item.id === this.local_item.period_id);

        this.local_item.vfdt = dayjs(period.from).format('YYYY') + '-01-01';
        this.local_item.vtdt = dayjs(period.from).format('YYYY') + '-12-31';

        this.local_item.period = period;

        this.local_item = { ...this.local_item }
      });
    },

    async create_member_company() {
      try {

        const res = await axios.post(`/member_company/${this.member.member_id}`, this.local_item);

        if (res.status === 201) {

          this.local_item.member_company_id = res.data.member_company_id;

          this.$emit('created', res.data);
        }
        else if (res.status === 403) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.PERMISSION_DENIED'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBERSHIP.UNABLE_CREATE'));
      }
    },

    async update_member_company() {

      try {

        const res = await axios.put(`/member_company/${this.local_item.member_company_id}`, this.local_item);

        if (res.status === 200) {
          this.$emit('updated', res.data);
        }
        else if (res.status === 403) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.PERMISSION_DENIED'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBERSHIP.UNABLE_UPDATE'));
      }
    },


    async save_data() {

      this.$emit('save_data', this.local_item);

    },

    async get_all_shop_items(company_id) {
      try {
        const res = await axios.get(`/shop_item/all`);

        if (res.status === 200) {
          this.shop_items = res.data;
          this.shop_items_options = [];

          for (const si of res.data) {

            if (si.is_ticket) {
              continue;
            }

            if (company_id !== null && si.company_id !== company_id) {
              continue;
            }

            this.shop_items_options.push({ value: si.shop_item_id, text: si.name });

          }
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta butiksartiklar');
      }

    },

  },


  data() {
    return {

      local_item: {},

      paying_options: [
        { value: true, text: this.$t('COMMON.PAID') },
        { value: false, text: this.$t('COMMON.NOT_PAID') }
      ],

      company_options: [],

      form: {},

      period_options: [],

      shop_items_options: [],

      selectedRowsMemberCompanyId: [],

      show_form: true,

      gender_options: [
        { text: this.$t('COMMON.MAN'), value: 'M' },
        { text: this.$t('COMMON.WOMAN'), value: 'F' },
        { text: this.$t('COMMON.OTHER'), value: 'O' },
        { text: this.$t('COMMON.UNKNOWN'), value: 'U' },
      ],

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
