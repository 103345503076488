<template>
  <div :class="classes">
    <label class="exp-checkbox-label">
      <input
        type="checkbox"
        :checked="value"
        :disabled="disabled"
        @change="onChange"
      />
      <span class="exp-checkbox-custom"></span>
      <span v-if="text" class="exp-checkbox-text">{{ text }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'TableCheckbox',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },
  methods: {
    onChange(event) {
      this.$emit('input', event.target.checked);
      this.$emit('change', event.target.checked);
    },
  },
  computed: {
    classes() {
      if (this.disabled) {
        return ['exp-checkbox-wrapper', 'ml-checkbox', 'disabled'];
      }

      return ['exp-checkbox-wrapper', 'ml-checkbox'];
    }
  }
};
</script>

<style scoped>
.exp-checkbox-wrapper {
  display: flex;
  align-items: center;
}

.exp-checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.exp-checkbox-label input {
  opacity: 0;
  width: 0;
  height: 0;
}

.exp-checkbox-custom {
  width: 24px; /* Increased size */
  height: 24px; /* Increased size */
  background-color: #fff;
  border: 2px solid #0085eb;
  border-radius: 4px;
  position: relative;
  transition: background-color 0.2s, border-color 0.2s;
}

.exp-checkbox-label input:checked ~ .exp-checkbox-custom {
  background-color: #0085eb;
  border-color: #0085eb;
}

.exp-checkbox-custom::after {
  content: "";
  position: absolute;
  display: none;
}

.exp-checkbox-label input:checked ~ .exp-checkbox-custom::after {
  display: block;
}

.exp-checkbox-custom::after {
  left: 6px; /* Adjusted for larger size */
  top: 2px;   /* Adjusted for larger size */
  width: 8px; /* Adjusted for larger size */
  height: 12px; /* Adjusted for larger size */
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.exp-checkbox-text {
  margin-left: 12px;
  font-size: 14px; /* Adjusted text size */
}
</style>
